import Vue from "vue";
import { validators } from "vue-form-generator";
import i18n from "@/plugins/i18n";
import fieldProducts from "@/components/form-components/fieldProducts";

Vue.component('fieldProducts', fieldProducts);

export const schema = {
  fields: [
    {
      type: "imageUpload",
      label: "images",
      model: "images",
      required: false,
      placeholder: "images",
      imageType: "emailBanner",
    },
    {
      type: "input",
      inputType: "text",
      label: "topic",
      model: "topic",
      required: true,
      placeholder: "topic",
      max: 255,
      validator: [
        validators.string.locale({
          fieldIsRequired: i18n.t("topicRequired"),
          textTooBig: i18n.t("topicMustBeLess{1}"),
        }),
      ]
    },
    {
      type: "input",
      inputType: "text",
      label: "title",
      model: "title",
      required: true,
      placeholder: "title",
      max: 255,
      validator: [
        validators.string.locale({
          fieldIsRequired: i18n.t("titleRequired"),
          textTooBig: i18n.t("titleMustBeLess{1}")
        }),
      ]
    },
    {
      type: "editor",
      label: "text",
      model: "text",
      required: true,
      placeholder: "text",
      validator: [
        validators.string.locale({
          fieldIsRequired: i18n.t("textIsRequired"),
        }),
      ]
    },
    {
      type: "tagMultiselect",
      url: "mail-group",
      urlField: "name",
      trackByField: "id",
      model: "groups",
      label: "Groups",
      placeholder: "SelectGroup",
    },
    {
      type: "array",
      label: "products",
      model: "newsletterProducts",
      validator: 'array',
      showRemoveButton: true,
      itemContainerClasses: "newsletter-form",
      newElementButtonLabelClasses: "btn btn-primary",
      newElementButtonLabel: i18n.t('addProduct'),
      removeElementButtonClasses: "btn btn-danger",
      removeElementButtonLabel: i18n.t('removeProduct'),
      items: {
        type: 'object',
        default: {},
        schema: {
          fields: [
            {
              type: 'products',
              label: '',
              model: 'product',
              validator: [
                validators.required.locale({
                  fieldIsRequired: i18n.t("codeRequired"),
                }),
              ],
            },
          ]
        }
      }
    }
  ]
};
