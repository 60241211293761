<template>
  <crud-form
    :schema="schema"
    :path="'mail-newsletter'"
    :form-title="$t('addNewsletter')"
  />
</template>

<script>
  import CrudForm from '../../components/CrudForm';
  import { schema } from './schema/newsletter'

  export default {
    name: 'NewsLettersList',
    components: {
      CrudForm
    },
    data: () => {
      return {
        schema,
      }
    },
  }
</script>

<style lang="scss">
  @import "./styles/newsletter.scss";
</style>
