<template>
  <b-row class="justify-content-center">
    <b-col xs="12" md="6">
      <multiselect
        v-model="product"
        label="brandName"
        track-by="id"
        open-direction="bottom"
        noResult="''"
        noOptions="''"
        :placeholder="$t('selectProduct')"
        :options="products"
        :multiple="false"
        :searchable="true"
        :loading="isLoading"
        :internal-search="false"
        :clear-on-select="true"
        :close-on-select="true"
        :show-no-results="false"
        :hide-selected="false"
        @search-change="asyncFind"
        @select="selectProduct"
      />
    </b-col>
    <b-col xs="12" md="6">
      <multiselect
        v-model="packing"
        label="value"
        track-by="id"
        open-direction="bottom"
        noResult="''"
        noOptions="''"
        :placeholder="$t('selectPacking')"
        :options="packings"
        :multiple="false"
        :searchable="false"
        :internal-search="false"
        :clear-on-select="true"
        :close-on-select="true"
        :show-no-results="false"
        :hide-selected="false"
        @select="selectPacking"
      />
    </b-col>
  </b-row>
</template>

<script>
  import Multiselect from 'vue-multiselect'
  import { abstractField } from 'vue-form-generator'
  import {APIService} from "@/services/api";

  export default {
    mixins: [abstractField],
    components: {
      Multiselect,
    },
    data () {
      return {
        product: null,
        packing: null,
        products: [],
        packings: [],
        isLoading: false
      }
    },
    methods: {
      async asyncFind (query) {
        if (query) {
          this.isLoading = true;
          try {
            const products = await APIService.get(`product/autocomplete/${query}`);
            this.products = products;
          }
          catch(e) {
            console.error(e);
          }
          this.isLoading = false
        }
      },
      selectProduct(selectedOption) {
        const packings = selectedOption.tagPriceItems;
        this.packings = packings.map(packing => {
          packing.value = packing.tagItem ? packing.tagItem.value : packing.id;
          return packing;
        });
        if (!this.value) {
          this.value = {
            product: null,
            packing: null,
          }
        }
        this.value.product = selectedOption
      },
      selectPacking(selectedOption) {
        this.value.packing = selectedOption;
      },
    }
  }
</script>
